// i18next-extract-mark-ns-start 404

import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { graphql } from 'gatsby';
import { Breadcrumb } from '@components/Breadcrumbs';

const NotFoundPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t('seo_title')}
				description={t('seo_description')}
			// title="404 Page Not Found | Realis Simulation"
			// description=""
			/>

			<article className="error__404">
				<div className="row">
					<div className="col xl8 offset-xl2 m12 offset-m0">
						<h1>
							<Trans i18nKey="sorry">
								Sorry, testing shows the page you requested has an
								error.
							</Trans>
						</h1>
					</div>
					<div className="col xl6 offset-xl3 m10 offset-m1">
						<h2>
							<Trans i18nKey="visit">
								Visit our <Link to="/">homepage</Link> or select
								from the menu above.
							</Trans>
						</h2>
					</div>
				</div>
			</article>
		</Page>
	);
};

export default NotFoundPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["404", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
	}
`;
